@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;600;700&display=swap");

@font-face {
  font-family: "LEMON_MILK";
  src: url("../public/fonts/LEMONMILK.otf");
}

@font-face {
  font-family: "MONOSPACE_TYPEWRITER";
  src: url("../public/fonts/MONOSPACETYPEWRITER.ttf");
}

body {
  font-family: "Nunito", sans-serif;
  overflow-x: hidden !important;
}

::-webkit-scrollbar {
  width: 0.2rem;
  border-radius: 0.6rem;
  background-color: #fff;
}

::-webkit-scrollbar-thumb {
  border-radius: 0.6rem;
  background-color: #21222c;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #363536;
}
